<template>
  <form @submit.prevent="refreshApp">
    <div class="box has-padding-300 has-text-centered content">
      <h2 class="is-size-3">New release &#x1F389;</h2>
      <p>
        A new version of
        <strong>{{ location }}</strong> is now available. To ensure things
        continue to run smoothly, we strongly suggest you refresh the app now.
      </p>
      <button
        class="button is-dark is-rounded is-medium has-margin-top-50"
        :class="{ 'is-loading': isProcessing }"
      >
        <span class="is-size-6">
          <b-icon icon="sync-alt" size="is-small" />
          <span>Refresh application</span>
        </span>
      </button>
      <p class="has-margin-top-25">
        <a class="has-text-grey is-size-7" @click="$emit('close')">
          <small>
            <u>I'll manually refresh later</u>
          </small>
        </a>
      </p>
    </div>
  </form>
</template>

<script>
export default {
  name: "RefreshAppModal",
  data() {
    return {
      isProcessing: false
    };
  },
  computed: {
    location() {
      return window.location.hostname;
    }
  },
  methods: {
    refreshApp() {
      if (this.isProcessing) return;
      this.isProcessing = true;
      location.reload(true);
    }
  }
};
</script>
